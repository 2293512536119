import Vue from 'vue'
import Router from 'vue-router'

// https://router.vuejs.org
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '', // Remove  class
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/view/Home')
    },
    {
      path: '/legal-notice',
      name: 'Legal Notice',
      component: () => import('@/view/LegalNotice')
    },
    {
      path: '/privacy-policy',
      name: 'Privacy Policy',
      component: () => import('@/view/PrivacyPolicy')
    }
  ],
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
