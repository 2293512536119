<template>
  <div id="app">
    <span class="hidden">
      <SvgStore />
    </span>
    <TheHeader />
    <main class="main">
      <transition name="fade">
        <router-view />
      </transition>
    </main>
    <TheFooter />
  </div>
</template>

<script>
import SvgStore from '@/component/SvgStore'
import TheHeader from '@/component/TheHeader'
import TheFooter from '@/component/TheFooter'

export default {
  name: 'App',
  components: {
    SvgStore,
    TheHeader,
    TheFooter
  },
  // MetaInfo via vue-meta HTML Metadata manager for Vue.js
  // https://vue-meta.nuxtjs.org/guide/metainfo.html
  metaInfo: {
    // If no subcomponents specify a metaInfo.title, this title will be used
    title: 'breckwoldt.net',
    // All titles will be injected into this template
    titleTemplate: '%s – Professional Front-end Architecture'
  }
}
</script>
