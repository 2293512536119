<template>
  <header class="header">
    <div class="container">
      <router-link
        to="/"
      >
        <dl>
          <dt class="logo">
            <span>{{ name }}</span>
          </dt>
          <dd>
            <mark class="mark">{{ claim }}</mark>
          </dd>
        </dl>
      </router-link>
    </div>
  </header>
</template>

<script>
module.exports = {
  data: function () {
    return {
      name: 'Olaf T. Breckwoldt',
      claim: 'Professional Front-end Architecture'
    }
  }
}
</script>

<style lang="scss">
.header {
  a.active {
    font-family: $font-regular;
    cursor: default;
  }
}
</style>
