import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import '@/registerServiceWorker'
import '@/scss/app.scss'
import Meta from 'vue-meta'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

// Enable Vue devtools
// https://vuejs.org/v2/api/#devtools
Vue.config.devtools = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

// AmazingDreams/vue-matomo - Vue plugin for Matomo Analytics
// https://github.com/AmazingDreams/vue-matomo
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://stats.breckwoldt.net/piwik',
  siteId: process.env.VUE_APP_MATOMO_SITEID,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: 'piwik',

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Whether or not to log debug information
  // Default: false
  debug: false
})

// console.log(process.env.VUE_APP_MATOMO_SITEID)
